import {
  CButton,
  CCol,
  CFormInput,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react';
import React, {useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Table.module.css';
import { AppContext } from '../../context/AppContext';
import TableFooter from './TableFooter';




const Table = ({ columns, data, pagination }) => {

  const {setSheltersPageUrl} = useContext(AppContext);

  //Navigation
  const navigate = useNavigate();


  return (
    <>
      {/* <CCol className="mt-4 mb-5">
        <CFormInput
          type="search"
          placeholder="Buscar por Nº de pedido"
          style={{ width: '100%' }}
        />
      </CCol>
      <CCol className="mt-4 mb-5">
        <CFormInput
          type="search"
          placeholder="Buscar por nombre o apellido"
          style={{ width: '100%' }}
        />
      </CCol> */}

        <CTable responsive hover>
          <CTableHead>
            <CTableRow>
              {columns.map((col, index) => (
                <CTableHeaderCell key={index} scope="col">
                  {col.label}
                </CTableHeaderCell>
              ))}
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {data?.map((shelter, index) => (
              <CTableRow key={index}>
                <CTableDataCell>{shelter.id}</CTableDataCell>
                <CTableDataCell>{shelter.name}</CTableDataCell>
                <CTableDataCell>
                  {shelter.rescuers.find(rescuer => rescuer.rescuer_role.id == 1).name}
                </CTableDataCell>
                <CTableDataCell>{shelter.email}</CTableDataCell>
                <CTableDataCell>{shelter.whatsapp}</CTableDataCell>
                <CTableDataCell>{shelter.locations.map(location => location.name).join(' - ')}</CTableDataCell>
                <CTableDataCell>
                <p className={[styles[`shelter-status-${shelter.status?.id}`], styles.statusContainer].join(' ')}>{shelter.status.name}</p>
                </CTableDataCell>
                <CTableDataCell>
                  <CButton 
                    className={`${styles['downloadQR']}`} 
                    style={{width:"max-content"}}
                    onClick={() => navigate(`/dashboard/shelters/${shelter?.id}`)}
                  >
                    Ver refugio
                  </CButton>
                </CTableDataCell>
              </CTableRow>
            ))}
          </CTableBody>
        </CTable>
        <TableFooter range={pagination?.links} setPageUrl={setSheltersPageUrl} />
    </>
  );
};

export default Table;
