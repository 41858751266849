import { CContainer, CRow, CCol } from '@coreui/react';
import React, {useEffect} from 'react';
import { CustomBreadcrumbs,  } from '../../components';
import BranchesTable from '../../components/Table/BranchesTable';
import { AppContext } from '../../context/AppContext';

const Branches = () => {

  const { state, getBranches, branchesPageUrl} = React.useContext(AppContext);
  const columns = [
    {
      key: 'company',
      label: 'Empresa',
      _props: { scope: 'col' }
    },
    {
      key: 'name',
      label: 'Sucursal',
      _props: { scope: 'col' }
    },
    {
      key: 'status',
      label: 'Estado',
      _props: { scope: 'col' }
    },
    {
      key: 'category',
      label: 'Categoría',
      _props: { scope: 'col' }
    },
    {
      key: 'email',
      label: 'Email',
      _props: { scope: 'col' }
    },
    {
      key: 'phone',
      label: 'Teléfono',
      _props: { scope: 'col' }
    },
    {
      key: 'whatsapp',
      label: 'Whatsapp',
      _props: { scope: 'col' }
    },
    {
      key: 'address',
      label: 'Dirección',
      _props: { scope: 'col' }
    },
    {
      key: 'actions',
      label: 'Acciones',
      _props: { scope: 'col' }
    },
  ];

  //Effects
  useEffect(() => {
    getBranches();
  }, [branchesPageUrl]);

  return (
    <div>
      <CustomBreadcrumbs parentTitle={'Veterinarias & lugares Pet Friendly'} activeTitle="Sucursales" />
      <CContainer>
        <CRow>
          <CCol xs={12}>
            <h2 className='title mb-4'>Sucursales</h2>
          </CCol>
          <BranchesTable
            columns={columns} 
            data={state?.branches} 
            pagination={state?.branchesPagination} 
          />
        </CRow>
      </CContainer>
    </div>
  );
};

export default Branches;
