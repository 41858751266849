import { CContainer, CRow, CCol } from '@coreui/react';
import { Outlet } from 'react-router-dom';
import styles from './DashboardOutlet.module.css';
// import logoHorizontal from '../../assets/images/logo-horizontal.svg';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
const DashboardOutlet = () => {
  return (
    <CContainer fluid>
      <div className={styles.sidebarContainer}>
        <Sidebar />
      </div>
      <div className={styles.outletContainer}>
        <Outlet />
      </div>
    </CContainer>
  );
};

export default DashboardOutlet;
