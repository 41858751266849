import {
  CButton,
  CCol,
  CFormInput,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react';
import React, { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import styles from './Table.module.css';
import TableFooter from './TableFooter';
import { AlertsContext } from '../../context/AlertsContext';
import { getDate } from '../../utils/getDate';



const Table = ({ columns, data, pagination }) => {

  const {setAlertsPageUrl} = useContext(AlertsContext);

  return (
    <>

        <CTable responsive hover>
          <CTableHead>
            <CTableRow>
              {columns?.map((col, index) => (
                <CTableHeaderCell key={index} scope="col">
                  {col.label}
                </CTableHeaderCell>
              ))}
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {data?.map((el, index) => (
              <CTableRow key={index}>
                <CTableDataCell>{getDate(el.date_of_alert)}</CTableDataCell>
                <CTableDataCell>{el.type.name}</CTableDataCell>
                <CTableDataCell>{el.status.name}</CTableDataCell>
                <CTableDataCell>{el.pet.name || "-"}</CTableDataCell>
                <CTableDataCell>{el.pet.specie}</CTableDataCell>
                <CTableDataCell>{el.pet.gender || "-"}</CTableDataCell>
                <CTableDataCell>{el.pet.age || "-"}</CTableDataCell>
                <CTableDataCell>{el.address}</CTableDataCell>
                <CTableDataCell>{el.responses}</CTableDataCell>
                {/* <CTableDataCell>
                  <CButton
                    className={el.is_active ? styles['isActive'] : styles['isSuspended']}
                    // onClick={() => handleAction(el.id)}
                  >
                    {el.is_active ? 'Suspender' : 'Reactivar'}
                  </CButton>
                </CTableDataCell> */}
              </CTableRow>
            ))}
          </CTableBody>
        </CTable>
        <TableFooter range={pagination?.links} setPageUrl={setAlertsPageUrl}/>
    </>
  );
};

export default Table;
