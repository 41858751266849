import React, { useEffect } from 'react';

import styles from './TableFooter.module.css';

const TableFooter = ({ range, setPageUrl, couponFooter }) => {
  
  return (
    <div className={styles.tableFooter}>
      {range?.map((el, index) => (
        <button
          key={index}
          className={`${styles.button} ${
            el.active ? styles.activeButton : styles.inactiveButton
          }`}
          onClick={() => setPageUrl(el.url)}>
          {el.label}
        </button>
      ))}
    </div>
  );
};

export default TableFooter;
