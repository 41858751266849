import { CContainer, CRow, CCol, CButton } from '@coreui/react';
import React, {useEffect, useContext} from 'react';
import { CustomBreadcrumbs} from '../../components';
import { AppContext } from '../../context/AppContext';
import styles from './Coupons.module.css';
import CouponsTable from '../../components/Table/CouponsTable';
import { useNavigate } from 'react-router-dom';


const Coupons = () => {

  const navigate = useNavigate();

  const { state, getCoupons, couponsPageUrl} = useContext(AppContext);
  const columns = [
    {
      key: 'id',
      label: '#',
      _props: { scope: 'col' }
    },
    {
      key: 'code',
      label: 'Código de cupón',
      _props: { scope: 'col' }
    },
    {
      key: 'couponeable_type',
      label: 'Tipo de cupón',
      _props: { scope: 'col' }
    },
    {
      key: 'description',
      label: 'Descripción',
      _props: { scope: 'col' }
    },
    {
      key: 'discount',
      label: 'Descuento',
      _props: { scope: 'col' }
    },
    {
      key: 'uses',
      label: 'Límite de usos',
      _props: { scope: 'col' }
    },
    {
      key: 'amount_uses',
      label: 'Cantidad de usos',
      _props: { scope: 'col' }
    },
    {
      key: 'expiration_date',
      label: 'Vencimiento',
      _props: { scope: 'col' }
    },
    {
      key: 'actions',
      label: 'Acciones',
      _props: { scope: 'col' }
    }
  ];
  const coupons = state?.coupons?.map((coupon) => {
    return {
      id: coupon.id,
      code: coupon.code,
      description: coupon.description,
      discount: coupon.discount,
      uses: coupon.uses || "-",
      remaining_uses: coupon.uses - coupon.used || "-",
      expiration_date: coupon.expiration_date
    };
  });

  //Effects
  useEffect(() => {
    getCoupons();
  }, [couponsPageUrl]);


  return (
    <div>
      <CustomBreadcrumbs parentTitle={'Cupones'} activeTitle="Cupones" />
      <CContainer>
        <CRow className="justify-content-center">
          <CCol lg={12}>
            <div className={styles.orderContainer}>
              {/* Order header */}
              <div className={styles.orderHeader}>
                  <h2 className='title'>Cupones</h2>
                  <CButton 
                      size="lg" 
                      color="primary" 
                      onClick={() => navigate("/dashboard/coupons/add")} 
                      className={styles.button}
                  >
                      Agregar cupón
                  </CButton>
              </div>
            </div>
            <div className={styles.productsContainer}>
              <CouponsTable
                columns={columns} 
                data={state?.coupons} 
                pagination={state?.couponsPagination}
              />
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Coupons;
