import { CContainer, CRow, CCol, CButton } from '@coreui/react';
import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomBreadcrumbs,  } from '../../components';
import LocationsTable from '../../components/Table/LocationsTable';
import { AppContext } from '../../context/AppContext';
import styles from './Locations.module.css';

const Locations = () => {

  const navigate = useNavigate();

  const { state, getLocations, locationsPageUrl } = React.useContext(AppContext);
  const columns = [
    {
      key: '',
      label: '#',
      _props: { scope: 'col' }
    },
    {
      key: 'name',
      label: 'Provincia',
      _props: { scope: 'col' }
    },
    {
      key: 'cities',
      label: 'Ciudades',
      _props: { scope: 'col' }
    },
    {
      key: 'actions',
      label: 'Acciones',
      _props: { scope: 'col' }
    }
  ];

  //Effects
  useEffect(() => {
    getLocations();
  }, [locationsPageUrl]);

  return (
    <div>
      <CustomBreadcrumbs parentTitle={'Refugios'} activeTitle="Ubicaciones" />
      <CContainer>
        <CRow>
          <CCol xs={12}>
            <div className={styles.orderContainer}>
              <div className={styles.orderHeader}>
                <h2 className='title'>Ubicaciones</h2>
                <CButton 
                    size="lg" 
                    color="primary" 
                    onClick={() => navigate("/dashboard/locations/add")} 
                    className={styles.button}
                >
                    Agregar ubicación
                </CButton>
              </div>
            </div>
          </CCol>
          <LocationsTable
            columns={columns} 
            data={state?.locations} 
            pagination={state?.locationsPagination} 
          />
        </CRow>
      </CContainer>
    </div>
  );
};

export default Locations;
