import { CButton, CCol, CContainer, CForm, CFormInput, CFormLabel, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import {CustomBreadcrumbs} from '../../components';
import { AppContext } from '../../context/AppContext';
import styles from './Location.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import CIcon from '@coreui/icons-react';
import {cilX} from '@coreui/icons';
import { v4 as uuidv4 } from 'uuid';



const Location = () => {

    //Navigation
    let {locationId} = useParams();
    const navigate = useNavigate();

    //Context
    const { state, dispatcher, locationApiBody, setLocationApiBody, getLocation, addLocation, editLocation, deleteLocation } = React.useContext(AppContext);

    const [cityFields, setCityFields] = useState([]);
    const [edit, setEdit] = useState({active:false, content:"", id:""});

    //Methods
    const handleInputChange = (e) =>{
        const {id, value} = e.target;
        setLocationApiBody({...locationApiBody, [id]:value})
    }
    const handleAction = (locationId) => {
        locationId ? 
        editLocation(locationId, navigate) : 
        addLocation(navigate);
    }
    const handleDeleteField = (id) => {
        setCityFields(cityFields.filter(field => field.id !== id));
    }
    const handleCities = (fieldId, name) => {
        const updatedFields = cityFields.map(field =>
            field.id === fieldId ? { ...field, name } : field
          );
        setCityFields(updatedFields);
    }

    useEffect(() => {
        getLocation(locationId);
        return () => {
            dispatcher({ type: 'set', location: {} });
        };
    },[locationId])

    useEffect(() => {
        setLocationApiBody({
            ...state?.location
        })
        state?.location?.children && 
        setCityFields(state?.location?.children?.map((child,i) => {
            return{
                name: child?.name, 
                id:`field-${i+1}`
            }
        }))
    },[state?.location])

    useEffect(() => {
        setLocationApiBody({
            ...locationApiBody,
            children: cityFields.map(city => {
                return {name: city.name}
            })
        })
    },[cityFields])


    return (
        <>
            <CustomBreadcrumbs parentTitle={'Ubicaciones'} activeTitle={locationId ? "Editar ubicación" : "Agregar ubicación"} />
            <CContainer>
                <CRow>
                    <CCol xs={12} lg={6}>
                        <div className={styles.orderContainer}>
                            {/* Order header */}
                            <div className={styles.orderHeader}>
                                <h2 className='title'> 
                                    {locationId ? "Editar ubicación" : "Agregar ubicación"}
                                </h2>
                            </div>
                        </div>
                        <CForm>
                            <div className="mb-3 d-flex">
                                <div className='w-100'>
                                    <CFormInput
                                        value={locationApiBody?.name}
                                        type="text"
                                        id="name"
                                        label="Provincia"
                                        placeholder="Provincia"
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <CFormLabel>
                                        Ciudades/Barrios
                                    </CFormLabel>
                                    <p 
                                        className={styles.addButton}
                                        onClick={() => setCityFields([{id:`field-${uuidv4()}`, name: ""}, ...cityFields])}
                                    >
                                        Agregar +
                                    </p>
                                </div>
                                {cityFields.map((field, i) => (
                                    <div 
                                        className='w-100 d-flex align-items-center mt-2'
                                        key={i}
                                    >
                                        <CFormInput
                                            value={cityFields.find(cityField => cityField.id == field.id).name}
                                            type="text"
                                            id={`field-${field.id}`}
                                            placeholder="Ciudad"
                                            onChange={(e) => handleCities(field.id, e.target.value)}
                                        />
                                        <CIcon 
                                            icon={cilX} 
                                            size="xl" 
                                            className="text-danger ms-3" 
                                            onClick={() => handleDeleteField(field.id)}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className="d-grid gap-2 d-md-block mt-4">
                                <CButton 
                                    className={styles.button}
                                    onClick={() => handleAction(locationId)}
                                >
                                    {locationId ? "Guardar cambios" : "Agregar ubicación"}
                                </CButton>
                                {locationId &&
                                    <CButton 
                                        onClick={() => {deleteLocation(locationId, navigate)}} 
                                        className={styles.buttonDelete}
                                    >
                                        Eliminar ubicación
                                    </CButton>
                                }
                                {!locationId &&
                                    <CButton 
                                        onClick={() => {navigate(-1); dispatcher({ type: 'set', location: {} });}} 
                                        className={styles.buttonCancel}
                                    >
                                        Cancelar
                                    </CButton>
                                }
                            </div>
                        </CForm>
                    </CCol>
                </CRow>
            </CContainer>
        </>
    );
};

export default Location;
