
import { CButton, CCol, CContainer, CFormInput, CRow } from '@coreui/react';
import React, { useContext, useEffect, useState } from 'react';
import { CardMetrics, CustomBreadcrumbs, PetsQRTable, } from '../../components';
import { AppContext } from '../../context/AppContext';
import paw from '../../assets/images/cil-paw.svg';
import cat from '../../assets/images/cil-cat.svg';
import dog from '../../assets/images/cil-dog.svg';
import styles from './ListPetsQR.module.css';


const ListUsers = () => {
  
  const { state, getPets, petsPageUrl, getDashboardData, searchPets } = useContext(AppContext);
  const [inputValues, setInputValues] = useState();

  const columns = [
    {
      key: 'id',
      label: '#',
      _props: { scope: 'col' }
    },
    {
      key: 'name',
      label: 'Nombre',
      _props: { scope: 'col' }
    },
    {
      key: 'specie',
      label: 'Especie',
      _props: { scope: 'col' }
    },
    {
      key: 'breed',
      label: 'Raza',
      _props: { scope: 'col' }
    },
    {
      key: 'gender',
      label: 'Sexo',
      _props: { scope: 'col' }
    },
    {
      key: 'pet_identification',
      label: 'RUF',
      _props: { scope: 'col' }
    },
    {
      key: 'actions',
      label: 'Acciones',
      _props: { scope: 'col'},
      alignEnd: true
    }
  ];
  
  const pets = state.pets.map((pet) => {
    return {
      id: pet?.id,
      name: pet?.name,
      specie: pet.specie?.name,
      breed: pet.breed?.name,
      gender: pet.gender?.name,
      pet_identification: pet?.pet_identification,
      users: pet?.users,
    };
  });

  const handleChange = (e) => {
    setInputValues({...inputValues, [e.target.id]:e.target.value})
  } 

  //Effects
  useEffect(() => {
    getPets();
    getDashboardData();
  }, [petsPageUrl]);

  return (
    <div>
      <CustomBreadcrumbs parentTitle={'Usuarios y mascotas'} activeTitle="Mascotas" />
      <CContainer>
        <CRow>
          <CCol xs={12}>
            <h2 className='title'>Resumen</h2>
          </CCol>
        </CRow>
        <CRow className="justify-content-between">
          <CCol xs={12} lg={4}>
            <CardMetrics 
              title={state?.stats?.pets && state?.stats?.pets["total"]} 
              subtitle="Mascotas totales" 
              cardStyle="primary" 
              icon={paw}
            />
          </CCol>
          <CCol xs={12} lg={4}>
              <CardMetrics 
                title={state?.stats?.pets && state?.stats?.pets["1"]} 
                subtitle="Perros" 
                cardStyle="secondary" 
                icon={dog}
              />
          </CCol>
          <CCol xs={12} lg={4}>
            <CardMetrics 
              title={state?.stats?.pets && state?.stats?.pets["2"]} 
              subtitle="Gatos" 
              cardStyle="tertiary" 
              icon={cat}
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol xs={12} lg={6}>
            <h2 className='title mb-4'>Mascotas</h2>
          </CCol>
          <CCol xs={12} lg={6}>
            <div className={styles.formContainer}>
              <CFormInput
                type="search"
                id="name"
                placeholder="Buscar por nombre"
                style={{ width: '100%' }}
                onChange={(e) => handleChange(e)}
              />
              <CFormInput
                type="search"
                id="ruf"
                placeholder="Buscar por RUF"
                style={{ width: '100%' }}
                onChange={(e) => handleChange(e)}
              />
              <CButton
                color='success'
                onClick={() => searchPets(inputValues?.name, inputValues?.ruf)}
              >
                Buscar
              </CButton>
            </div>
          </CCol>
          <CCol xs={12}>
            <PetsQRTable 
              columns={columns} 
              data={pets} 
              pagination={state?.petsPagination} 
            />
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ListUsers;
