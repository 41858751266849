import {
  CButton,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react';
import React, {useContext, useState} from 'react';
import styles from './Table.module.css';
import TableFooter from './TableFooter';
import { AppContext } from '../../context/AppContext';
import { ErrorHandler } from '../../utils/ErrorHandler';
import axios from 'axios';
import {API_URL} from '../../constants/index.js';
import CIcon from '@coreui/icons-react';
import { cilChevronBottom, cilChevronCircleDownAlt } from '@coreui/icons';


const Table = ({ columns, data, pagination }) => {

  const {setPetsPageUrl} = useContext(AppContext);

  const [showDropdown, setShowDropdown] = useState(false);

  const handleAction = (id, userId, type, name) => {
    const idToString = id.toString();
    const petId = idToString.slice(0, idToString.length - 2);
    function downloadURI(uri, name) {
      var link = document.createElement("a");
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    if( type == 'ruf'){
      axios
        .get(`${API_URL}admin/badge-requests/${type}/${petId}/${userId}`, {responseType:"blob"})
        .then((res) => {
          let reader = new window.FileReader();
          reader.readAsDataURL(res.data); 
          reader.onload = function() {
          let imageDataUrl = reader.result;
          downloadURI(imageDataUrl, `Findpet - ${id} - ${type.toUpperCase()} - ${name}.png`);
        }})
        .catch((err) => ErrorHandler(err));
    }else{
      axios
      .get(`${API_URL}admin/pets/${petId}/${type}`, {responseType:"blob"})
      .then((res) => {
        let reader = new window.FileReader();
        reader.readAsDataURL(res.data); 
        reader.onload = function() {
        let imageDataUrl = reader.result;
        downloadURI(imageDataUrl, `Findpet - ${id} - ${type.toUpperCase()} - ${name}`);
      }})
      .catch((err) => ErrorHandler(err));
    }
  }

  return (
    <>
      {/* <CCol className="mt-4 mb-5">
        <CFormInput
          type="search"
          placeholder="Buscar por nombre de mascota"
          style={{ width: '100%' }}
        />
      </CCol>
      <CCol className="mt-4 mb-5">
        <CFormInput
          type="search"
          placeholder="Buscar por N de RUF"
          style={{ width: '100%' }}
        />
      </CCol> */}
      {data.length !== 0 ?
        <>
          <CTable responsive hover style={{width:"100%"}}>
            <CTableHead>
              <CTableRow>
                {columns.map((col, index) => (
                  <CTableHeaderCell key={index} scope="col" style={{textAlign:col.alignEnd ? "end" : "start", width:(col.key === "name" || col.key === "breed" || col.key === "specie") ? "16%" : "auto" }}>
                    {col.label}
                  </CTableHeaderCell>
                ))}
              </CTableRow>
            </CTableHead>
            <CTableBody>
                {data.map((el, index) => (
                  <CTableRow key={index}>
                    <CTableDataCell>{el.id}</CTableDataCell>
                    <CTableDataCell>{el.name}</CTableDataCell>
                    <CTableDataCell>{el.specie}</CTableDataCell>
                    <CTableDataCell>{el.breed}</CTableDataCell>
                    <CTableDataCell>{el.gender}</CTableDataCell>
                    <CTableDataCell>{el.pet_identification}</CTableDataCell>
                    <CTableDataCell style={{textAlign:'end', display:"flex", flexDirection: "column", alignItems:"flex-end"}}>
                      <div className="d-flex">
                        <CButton className={styles['downloadQr']} onClick={() => handleAction(
                            el.pet_identification, 
                            null, 
                            "qr",
                            el.name
                          )}
                        >
                          Descargar QR
                        </CButton>
                        <CButton className={styles['downloadRuf']} onClick={() => setShowDropdown({id: el.pet_identification, show:!showDropdown.show})}>
                          Descargar RUF 
                          <CIcon icon={cilChevronCircleDownAlt} style={{marginLeft:6}}/>
                        </CButton>
                      </div>
                      {(showDropdown.id == el.pet_identification && showDropdown.show) &&
                        <div className={styles.userPetDropdown}>
                          {el?.users?.map((user,i) => (
                            <p key={i} onClick={() => handleAction(
                                el.pet_identification, 
                                user.id, 
                                'ruf',
                                el.name
                              )}
                            >
                              {user.name}
                            </p>
                          ))}
                        </div>
                      }
                    </CTableDataCell>
                  </CTableRow>
                ))}
            </CTableBody>
          </CTable>
          <TableFooter range={pagination?.links} setPageUrl={setPetsPageUrl} />
        </> : 
        <p style={{textAlign:"center", fontSize:20, marginTop:20, fontWeight:600}}>No se encontraron resultados.</p>
      }
    </>
  );
};

export default Table;
