import { CButton, CCol, CContainer, CForm, CFormInput, CFormLabel, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import {CustomBreadcrumbs} from '../../components';
import { AppContext } from '../../context/AppContext';
import styles from './Sponsor.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { PUBLIC_URL } from '../../constants';



const Sponsor = () => {

    //Navigation
    let {sponsorId} = useParams();
    const navigate = useNavigate();

    //Context
    const { state, dispatcher, sponsorApiBody, setSponsorApiBody, getSponsor, addSponsor, editSponsor, deleteSponsor } = React.useContext(AppContext);

    const [file, setFile] = useState(sponsorApiBody.file || '');

    //Methods
    const handleInputChange = (e) =>{
        const {id, value} = e.target;
        setSponsorApiBody({...sponsorApiBody, [id]:value})
    }
    const handleAction = (sponsorId) => {
        sponsorId ? 
        editSponsor(sponsorId, navigate) : 
        addSponsor(navigate);
    }
    const handleFile = (e) => {
        setFile(URL.createObjectURL(e.target.files[0]))
        setSponsorApiBody({...sponsorApiBody, logo:e.target.files[0]})
    }

    useEffect(() => {
        getSponsor(sponsorId);
        return () => {
            dispatcher({ type: 'set', sponsor: {} });
        };
    },[sponsorId])

    useEffect(() => {
        setSponsorApiBody({
            name: state?.sponsor?.name,
            logo: state?.sponsor?.logo,
        })
        sponsorId && setFile(PUBLIC_URL + state?.sponsor?.logo?.path)
    },[state?.sponsor])


    return (
        <>
            <CustomBreadcrumbs parentTitle={'Auspiciantes'} activeTitle={sponsorId ? "Editar auspiciante" : "Agregar auspiciante"} />
            <CContainer>
                <CRow>
                    <CCol xs={12} lg={6}>
                        <div className={styles.orderContainer}>
                            {/* Order header */}
                            <div className={styles.orderHeader}>
                                <h2 className='title'> 
                                    {sponsorId ? "Editar auspiciante" : "Agregar auspiciante"}
                                </h2>
                            </div>
                        </div>
                        <CForm>
                            <div className="mb-3 d-flex">
                                <div className='px-2 w-100'>
                                    <CFormInput
                                        value={sponsorApiBody?.name}
                                        type="text"
                                        id="name"
                                        label="Nombre"
                                        placeholder="Nombre"
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="mb-3 d-flex">
                                <div className='px-2 w-100'>
                                    <CFormLabel htmlFor="formFile">
                                        Imagen
                                    </CFormLabel>
                                    {file &&
                                        <div className={styles.imgPreview}>
                                            <img src={file} alt="Img"/>
                                        </div>
                                    }
                                    <CFormInput
                                        value={file.name}
                                        type="file"
                                        id="file"
                                        onChange={(e) => handleFile(e)}
                                    />
                                    <p className='mt-2'>La imagen debe ser <b>cuadrada de 300x300 px.</b></p>
                                </div>
                            </div>
                            <div className="d-grid gap-2 d-md-block mt-5">
                                <CButton 
                                    className={styles.button}
                                    onClick={() => handleAction(sponsorId)}
                                >
                                    {sponsorId ? "Guardar cambios" : "Agregar auspiciante"}
                                </CButton>
                                {sponsorId &&
                                    <CButton 
                                        onClick={() => {deleteSponsor(sponsorId, navigate); setFile({})}} 
                                        className={styles.buttonDelete}
                                    >
                                        Eliminar auspiciante
                                    </CButton>
                                }
                                {!sponsorId &&
                                    <CButton 
                                        onClick={() => {navigate(-1); dispatcher({ type: 'set', sponsor: {} });}} 
                                        className={styles.buttonCancel}
                                    >
                                        Cancelar
                                    </CButton>
                                }
                            </div>
                        </CForm>
                    </CCol>
                </CRow>
            </CContainer>
        </>
    );
};

export default Sponsor;
