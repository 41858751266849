import { CButton, CCol, CContainer, CModal, CModalBody, CModalHeader, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import {CustomBreadcrumbs} from '../../components';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';
import styles from './Shelter.module.css';
import back from '../../assets/images/cil-arrow-left.svg';
import { useNavigate } from 'react-router-dom';
import paw from '../../assets/images/cil-paw.svg';
import ShelterAnimalsTable from '../../components/Table/ShelterAnimalsTable';
import rrss from '../../assets/images/cil-paper-plane.svg';
import home from '../../assets/images/cil-house.svg';
import users from '../../assets/images/cil-user.svg';
import spinner from '../../assets/images/spinner.gif';
import chevron from '../../assets/images/chevron-down.svg';




const Shelter = () => {

    const { state, getShelter, dispatcher, getAnimalsByShelterId, submitSuccess, loading, editShelterStatus, setFeaturedShelter } = React.useContext(AppContext);

    //Navigation
    let { shelterId } = useParams();
    const navigate = useNavigate();

    const [shelterStatus, setShelterStatus] = useState({});
    const [showDropdown, setShowDropdown] = useState(false);
    const [showModal, setShowModal] = useState(false);
    
    //Effect
    useEffect(() => {
        getShelter(shelterId);
        getAnimalsByShelterId(shelterId);
        return () => {
            dispatcher({ type: 'set', shelter: {} });
            dispatcher({ type: 'set', shelterPets: [] });
        };
    },[shelterId])

    useEffect(() => {
        setShelterStatus({
            id:state?.shelter?.status?.id, 
            name: state?.shelter?.status?.name
        })
    },[state.shelter])


    return (
        <div>
            <CustomBreadcrumbs parentTitle={'Refugios'} activeTitle={`Refugios ${state?.shelter?.name}`} />
            <CContainer>
                <CRow className="justify-content-between">
                    <CCol lg={12}>
                        <CRow className="justify-content-center">
                            <CCol lg={8}>
                                <div 
                                    className={styles.backButtonContainer}
                                    onClick={() => navigate(-1)}
                                >    
                                    <img src={back} width="20" alt="back" />
                                    <p className={styles.backButton}>Volver</p>
                                </div>
                            </CCol>
                            <CCol lg={8}>
                                <div className={styles.orderContainer}>
                                    {/* Order header */}
                                    <div className={styles.orderHeader}>
                                        <h2 className='title'>Refugio {state?.shelter?.name}</h2>
                                        <div 
                                            className={styles.statusContainer}
                                            onClick={() => setShowDropdown(!showDropdown)}
                                        >
                                            <p  className={[styles[`status-${shelterStatus.id}`],styles.status].join(' ')}>
                                                {shelterStatus.name}
                                            </p>
                                            <img src={chevron} alt="chevron" />
                                            {showDropdown &&
                                                <div className={styles.orderStatusDropdown}>
                                                    <p 
                                                        onClick={() => {
                                                            setShowDropdown(false); 
                                                            setShelterStatus({
                                                                id:1,
                                                                name:"Pendiente"
                                                            })
                                                        }}
                                                    >
                                                        Pendiente
                                                    </p>
                                                    <p 
                                                        onClick={() => {
                                                            setShowDropdown(false); 
                                                            setShelterStatus({
                                                                id:2,
                                                                name:"Activo"
                                                            })
                                                        }}
                                                    >
                                                        Activo
                                                    </p>
                                                    <p 
                                                        onClick={() => {
                                                            setShowDropdown(false); 
                                                            setShelterStatus({
                                                                id:3,
                                                                name:"Suspendido"
                                                            })
                                                        }}
                                                    >
                                                        Suspendido
                                                    </p>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    {/* Order Body */}
                                    <div className={styles.orderBodySection}>
                                        <div className={[styles['orderSectionIcon'], styles['primary']].join(' ')}>
                                            <img src={home} alt="icon" />
                                        </div>
                                        <CRow className='w-100'>
                                            <CCol xs={12}>
                                                <div className={styles.orderSectionContent}>
                                                    <h3 className={styles.orderSubtitle}>Datos del refugio</h3>
                                                </div>
                                            </CCol>
                                            <CCol lg={6}>
                                                <div className={styles.orderSectionContent}>
                                                    <p><b>Nombre: </b>{state?.shelter?.name}</p>
                                                    <p><b>Email: </b>{state?.shelter?.email}</p>
                                                    <p><b>Whatsapp: </b>
                                                    {state?.shelter?.whatsapp ?
                                                        <a href={`https://wa.me/${state?.shelter?.whatsapp}`}>
                                                            {state?.shelter?.whatsapp}
                                                        </a> : "-"
                                                    }
                                                    </p>
                                                    <p><b>Especies: </b>
                                                        {state?.shelter?.species?.map(specie => specie.name).join(' - ')}
                                                    </p>
                                                    <p><b>Formulario de adopción: </b>
                                                        {state?.shelter?.adoption_form_link ? 
                                                            <a href={state?.shelter?.adoption_form_link} target="_blank">
                                                                {state?.shelter?.adoption_form_link}
                                                            </a> : '-'
                                                        }
                                                    </p>
                                                    <p><b>Refugio es destacado: </b>
                                                        {state?.shelter?.is_featured ? "Sí" : "No"}
                                                    </p>
                                                    <CButton
                                                        className={!state?.shelter?.is_featured ? styles.habilitar : styles.deshabilitar}
                                                        onClick={() => setShowModal(true)}
                                                    >
                                                        {state?.shelter?.is_featured ? "No quiero destacarlo" : "Quiero destacarlo"}
                                                    </CButton>
                                                </div>
                                            </CCol>
                                            <CCol lg={6}>
                                                <div className={styles.orderSectionContent}>
                                                    <p><b>Ubicaciones: </b></p>
                                                    <ul>
                                                        {state?.shelter?.locations?.map((location,i) => (
                                                            <li key={i}>
                                                                {location.name}
                                                                {location.children && 
                                                                    <ul>
                                                                       {location.children.map((child,i) => (
                                                                        <li key={i}>{child.name}</li>
                                                                       ))} 
                                                                    </ul>
                                                                }
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </CCol>
                                        </CRow>
                                    </div>
                                    <div className={styles.orderBodySection}>
                                        <div className={[styles['orderSectionIcon'], styles['secondary']].join(' ')}>
                                            <img src={rrss} alt="icon" />
                                        </div>
                                        <div className={styles.orderSectionContent}>
                                            <h3 className={styles.orderSubtitle}>Redes sociales</h3>
                                            <p><b>Sitio web: </b>
                                             {state?.shelter?.website_url ?
                                                <a href={state?.shelter?.website_url} target="_blank">
                                                {state?.shelter?.website_url}
                                                </a> : "-"
                                            }
                                            </p>
                                            <p><b>Facebook: </b>
                                             {state?.shelter?.facebook_url ? 
                                                <a href={`https://www.facebook.com/${state?.shelter?.facebook_url}`} target="_blank">
                                                    {state?.shelter?.facebook_url}
                                                </a> : "-"
                                            }
                                            </p>
                                            <p><b>Instagram: </b>
                                               {state?.shelter?.instagram_handle ? 
                                                    <a href={`https://www.instagram.com/${state?.shelter?.instagram_handle}`} target="_blank">
                                                        {state?.shelter?.instagram_handle}
                                                    </a> : "-"
                                                }
                                            </p>
                                            <p><b>Twitter: </b>
                                                {state?.shelter?.twitter_handle ? 
                                                    <a href={`https://twitter.com/${state?.shelter?.twitter_handle}`} target="_blank">
                                                        {state?.shelter?.twitter_handle}
                                                    </a> : "-"
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className={styles.orderBodySection}>
                                        <div className={[styles['orderSectionIcon'], styles['success']].join(' ')}>
                                            <img src={users} alt="icon" />
                                        </div>
                                        
                                        <div className={styles.orderSectionContent}>
                                            <h3 className={styles.orderSubtitle}>Miembros</h3>
                                            <h5 className="my-3"><b>Fundadores</b></h5>
                                            <CRow>
                                                {state?.shelter && 
                                                state?.shelter?.rescuers?.filter(rescuer => rescuer.rescuer_role.id == 1).map((rescuer,i) => (
                                                    <CCol xs={12} lg={6} key={i}>
                                                        <div className={styles.cardUser}>
                                                            <p><b>{rescuer.name}</b></p>
                                                            <p>{rescuer.email}</p>
                                                        </div>
                                                    </CCol>
                                                ))}
                                            </CRow>
                                            <h5 className="my-3"><b>Voluntarios</b></h5>
                                            <CRow>
                                                {state?.shelter?.rescuers?.filter(rescuer => rescuer.rescuer_role.id != 1) ?
                                                <CCol>
                                                    <p>El refugio todavía no tiene miembros voluntarios.</p>
                                                </CCol> : 
                                                this?.map((rescuer,i) => (
                                                    <CCol xs={12} lg={6} key={i}>
                                                        <div className={styles.cardUser}>
                                                            <p><b>{rescuer.name}</b></p>
                                                            <p>{rescuer.email}</p>
                                                        </div>
                                                    </CCol>
                                                ))}
                                            </CRow>
                                        </div>
                                    </div>
                                    <div className={styles.orderBodySection}>
                                        <div className={[styles['orderSectionIcon'], styles['tertiary']].join(' ')}>
                                            <img src={paw} alt="icon" />
                                        </div>
                                        <div className={styles.orderSectionContent}>
                                            <h3 className={styles.orderSubtitle}>Animalitos</h3>
                                            {state?.shelterPets?.length != 0 ? 
                                                <CRow>
                                                   {state?.shelterPets && 
                                                   state?.shelterPets?.filter(pet => pet.specie.id === 1) &&
                                                    <>
                                                        <CCol xs={12}>
                                                            <h5 className="my-3"><b>Perros</b></h5>
                                                        </CCol>
                                                        <CCol xs={12}>
                                                            <ShelterAnimalsTable
                                                                data={
                                                                    state?.shelterPets && 
                                                                    state?.shelterPets?.filter(pet => pet.specie.id === 1)
                                                                }
                                                            />
                                                        </CCol>
                                                    </>
                                                    }
                                                    {state?.shelterPets && 
                                                    state?.shelterPets?.filter(pet => pet.specie.id === 2) &&
                                                        <>
                                                            <CCol xs={12}>
                                                                <h5 className="my-3"><b>Gatos</b></h5>
                                                            </CCol>
                                                            <CCol xs={12}>
                                                                <ShelterAnimalsTable
                                                                    data={
                                                                        state?.shelterPets && 
                                                                        state?.shelterPets?.filter(pet => pet.specie.id === 2)
                                                                    }
                                                                />
                                                            </CCol>
                                                        </>
                                                    }
                                                </CRow> :
                                                <CRow>
                                                    <CCol xs={12}>
                                                        <p>El refugio todavía no cargó ningún animalito.</p>
                                                    </CCol>
                                                </CRow>
                                            }
                                        </div>
                                    </div>
                                    <br/>
                                    <div
                                        style={{textAlign:"right"}}
                                    >
                                        <a 
                                            className={submitSuccess ? 'successBtn' :'generalBtn'}
                                            onClick={() => editShelterStatus(shelterId, shelterStatus.id,navigate)}
                                            disabled={loading && submitSuccess}
                                        >
                                            {
                                                loading ? 
                                                <img src={spinner} width="15" alt="loader"/> :
                                                submitSuccess ?
                                                "¡Cambios guardados con éxito!" :
                                                "Guardar cambios"
                                            }
                                        </a>
                                    </div>
                                </div>
                            </CCol>
                        </CRow>
                    </CCol>
                </CRow>
            </CContainer>
            <CModal visible={showModal} onClose={() => setShowModal(false)}>
                <CModalHeader>
                    {!state?.shelter?.is_featured ? "Destacar refugio" : "Quitar destacado"}
                </CModalHeader>
                <CModalBody>
                    {!state?.shelter?.is_featured ?
                        <p>¿Querés destacar este refugio?<br/>Si destacás este refugio, aparecerá entre los primeros refugios de la lista de refugios de la app.<br/>Luego podrás deshacer esta acción.</p> :
                        <p>¿Querés que este refugio deje de ser destacado?<br/>Si el refugio deja de ser destacado, el refugio dejará de aparecer primero en la lista de refugios de la app.<br/>Luego podrás deshacer esta acción.</p>
                    }
                    <CButton
                        className={!state?.shelter?.is_featured ? styles.habilitar : styles.deshabilitar}
                        onClick={!state?.shelter?.is_featured ? 
                            () => {setFeaturedShelter(shelterId, 1, navigate); setShowModal(false)} : 
                            () => {setFeaturedShelter(shelterId, 0, navigate); setShowModal(false)}
                        }
                    >
                        {!state?.shelter?.is_featured ? "Destacar refugio" : "Quitar destacado"}
                    </CButton>
                </CModalBody>
            </CModal>
        </div>
    );
};

export default Shelter;
