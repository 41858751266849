
import { AuthProvider } from './context/AuthContext';
import { AppProvider } from './context/AppContext';
import CustomRoutes from './routes/routes';
import { AlertsProvider } from './context/AlertsContext';

function App() {
  return (
    <AuthProvider>
      <AppProvider>
        <AlertsProvider>
          <CustomRoutes />
        </AlertsProvider>
      </AppProvider>
    </AuthProvider>
  );
}

export default App;
