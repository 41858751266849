import { CContainer, CRow, CCol, CFormInput, CButton, CFormSelect } from '@coreui/react';
import React, {useEffect, useContext, useState} from 'react';
import { CardMetrics, CustomBreadcrumbs} from '../../components';
import home from '../../assets/images/cil-house.svg';
import alert from '../../assets/images/cil-bullhorn.svg';
import styles from './ListAlerts.module.css';
import { AlertsContext } from '../../context/AlertsContext';
import AlertsTable from '../../components/Table/AlertsTable';
import { AppContext } from '../../context/AppContext';
import CIcon from '@coreui/icons-react';
import { cilCloudDownload } from '@coreui/icons';


const ListAlerts = () => {

  const { alertsState, getAlerts, alertsPageUrl, getPetGenders, getPetSpecies, getAlertTypes, getAlertStatuses, getSummaryAlerts, getAlertsFile} = useContext(AlertsContext);
  const {state, getDashboardData} = useContext(AppContext);

  const columns = [
    {
      key: 'created_at',
      label: 'Fecha creación',
      _props: { scope: 'col' }
    },
    {
      key: 'alert_type',
      label: 'Tipo',
      _props: { scope: 'col' }
    },
    {
      key: 'alert_status',
      label: 'Estado',
      _props: { scope: 'col' }
    },
    {
      key: 'pet_name',
      label: 'Nombre',
      _props: { scope: 'col' }
    },
    {
      key: 'pet_specie',
      label: 'Especie',
      _props: { scope: 'col' }
    },
    {
      key: 'pet_gender',
      label: 'Género',
      _props: { scope: 'col' }
    },
    {
      key: 'pet_age',
      label: 'Edad',
      _props: { scope: 'col' }
    },
    {
      key: 'address',
      label: 'Dirección',
      _props: { scope: 'col' }
    },
    {
      key: 'answers',
      label: 'Respuestas',
      _props: { scope: 'col' }
    },
    // {
    //   key: 'actions',
    //   label: 'Acciones',
    //   _props: { scope: 'col' }
    // }
  ];

  const [alertsFilters, setAlertsFilters] = useState({});

  const handleChange = (e) => {
    setAlertsFilters({
      ...alertsFilters, 
      [e.target.id]:e.target.value,
    })
  }

  const handleSearch = () => {
    getAlerts(alertsFilters);
  }
  console.log(alertsState)
  //Effects
  useEffect(() => {
    getPetGenders();
    getPetSpecies();
    getAlertTypes();
    getAlertStatuses();
    getDashboardData();
    getSummaryAlerts();
  }, []);


  useEffect(() => {
    getAlerts(alertsFilters);
  },[alertsPageUrl])

  return (
    <div>
      <CustomBreadcrumbs parentTitle={'Usuarios y mascotas'} activeTitle="Alertas" />
      <CContainer>
        <CRow className="justify-content-between">
          <CCol xs={12} lg={6}>
            <h2 className='title'>Resumen</h2>
          </CCol>
          <CCol className='d-flex justify-content-end'>
            <CButton
              color='success'
              onClick={() => getAlertsFile()}
            >
              <CIcon icon={cilCloudDownload} style={{ marginRight: 8}}/>
              Descargar reporte completo
            </CButton>
          </CCol>
        </CRow>
        <CRow>
          <CCol xs={12} lg={4}>
            <CardMetrics
              title={alertsState?.alertsSummary?.lost}
              subtitle="Perdidos"
              cardStyle="error"
              icon={alert}
            />
          </CCol>
          <CCol xs={12} lg={4}>
            <CardMetrics
              title={alertsState?.alertsSummary?.found}
              subtitle="Encontrados"
              cardStyle="tertiary"
              icon={alert}
            />
          </CCol>
          <CCol xs={12} lg={4}>
            <CardMetrics
              title={alertsState?.alertsSummary?.finished}
              subtitle="En casa"
              cardStyle="success"
              icon={home}
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol xs={12} lg={4}>
            <h2 className='title mb-4'>Alertas</h2>
          </CCol>
          <CCol xs={12} lg={8}>
            <div>
              <form className={styles.formContainer}>
              <CFormSelect
                id="type"
                style={{ width: '100%', marginRight:8 }}
                onChange={(e) => handleChange(e)}
                defaultValue="default"
              >
                <option value="default" selected disabled>Tipo de alerta</option>
                {alertsState?.alertTypes?.map((option,i) => (
                  <option value={option.id} key={i}>
                    {option.name}
                  </option>
                ))}
              </CFormSelect>
              <CFormSelect
                id="status"
                style={{ width: '100%', marginRight:8 }}
                onChange={(e) => handleChange(e)}
                defaultValue="default"
              >
                <option value="default" selected disabled>Estado de alerta</option>
                {alertsState?.alertStatuses?.map((option,i) => (
                  <option value={option.id} key={i}>
                    {option.name}
                  </option>
                ))}
              </CFormSelect>
              <CFormSelect
                id="gender"
                style={{ width: '100%', marginRight:8 }}
                onChange={(e) => handleChange(e)}
                defaultValue="default"
              >
                <option value="default" selected disabled>Género</option>
                {alertsState?.petGenders?.map((option,i) => (
                  <option value={option.id} key={i}>
                    {option.name}
                  </option>
                ))}
              </CFormSelect>
              <CFormSelect
                id="specie"
                style={{ width: '100%', marginRight:8 }}
                onChange={(e) => handleChange(e)}
                defaultValue="default"
              >
                <option value="default" selected disabled>Especie</option>
                {alertsState?.petSpecies?.map((option,i) => (
                  <option value={option.id} key={i}>
                    {option.name}
                  </option>
                ))}
              </CFormSelect>
              <CButton
                color='success'
                onClick={() => handleSearch()}
                type="reset"
              >
                Buscar
              </CButton>
              </form>
            </div>
          </CCol>
          <AlertsTable
            columns={columns} 
            data={alertsState?.alerts} 
            pagination={alertsState?.alertsPagination} 
          />
        </CRow>
      </CContainer>
    </div>
  );
};

export default ListAlerts;
