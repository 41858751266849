import { CContainer, CRow, CCol, CFormInput, CButton } from '@coreui/react';
import React, {useEffect, useContext, useState} from 'react';
import { CardMetrics, CustomBreadcrumbs, UserTable } from '../../components';
import { AppContext } from '../../context/AppContext';
import user from '../../assets/images/cil-user.svg';
import styles from './ListUsers.module.css';


const ListUsers = () => {

  const { state, getUsers, usersPageUrl, searchUsers } = useContext(AppContext);
  const columns = [
    {
      key: 'id',
      label: '#',
      _props: { scope: 'col' }
    },
    {
      key: 'name',
      label: 'Nombre',
      _props: { scope: 'col' }
    },
    {
      key: 'email',
      label: 'Email',
      _props: { scope: 'col' }
    },
    {
      key: 'alternative_email',
      label: 'Email de contacto',
      _props: { scope: 'col' }
    },
    {
      key: 'phone',
      label: 'Teléfono',
      _props: { scope: 'col' }
    },
    // {
    //   key: 'actions',
    //   label: 'Acciones',
    //   _props: { scope: 'col' }
    // }
  ];
  const users = state.users.map((user) => {
    return {
      id: user.id,
      name: user.name,
      email: user.email,
      phone: user.phone,
      is_active: user.is_active
    };
  });
  const [inputValues, setInputValues] = useState();

  const handleChange = (e) => {
    setInputValues({...inputValues, [e.target.id]:e.target.value})
  } 

  //Effects
  useEffect(() => {
    getUsers();
  }, [usersPageUrl]);


  return (
    <div>
      <CustomBreadcrumbs parentTitle={'Usuarios y mascotas'} activeTitle="Usuarios" />
      <CContainer>
        <CRow className="justify-content-between">
          <CCol xs={12}>
            <h2 className='title'>Resumen</h2>
          </CCol>
          <CCol xs={12} lg={4}>
            <CardMetrics
              title={state?.users?.filter((u) => u.is_active)?.length}
              subtitle="Usuarios activos"
              cardStyle="primary"
              icon={user}
            />
          </CCol>
          <CCol xs={12} lg={4}>
            <CardMetrics
              title={state?.users?.filter((u) => !u.is_active)?.length}
              subtitle="Usuarios inactivos"
              cardStyle="secondary"
              icon={user}
            />
          </CCol>
          <CCol xs={12} lg={4}>
            <CardMetrics
              title={state?.users?.filter((u) => u.rol === 'admin')?.length}
              subtitle="Usuarios administradores"
              cardStyle="tertiary"
              icon={user}
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol xs={12} lg={6}>
            <h2 className='title mb-4'>Usuarios</h2>
          </CCol>
          <CCol xs={12} lg={6}>
            <div className={styles.formContainer}>
              <CFormInput
                type="search"
                id="name"
                placeholder="Buscar por nombre y apellido"
                style={{ width: '100%' }}
                onChange={(e) => handleChange(e)}
              />
              <CFormInput
                type="search"
                id="email"
                placeholder="Buscar por email"
                style={{ width: '100%' }}
                onChange={(e) => handleChange(e)}
              />
              <CFormInput
                type="search"
                id="id"
                placeholder="Buscar por ID"
                style={{ width: '100%' }}
                onChange={(e) => handleChange(e)}
              />
              <CButton
                color='success'
                onClick={() => searchUsers(inputValues)}
              >
                Buscar
              </CButton>
            </div>
          </CCol>
          <UserTable 
            columns={columns} 
            data={users} 
            pagination={state?.usersPagination} 
          />
        </CRow>
      </CContainer>
    </div>
  );
};

export default ListUsers;
