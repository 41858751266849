import React from 'react';
import {
  CNavItem,
  CNavLink,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler
} from '@coreui/react';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import CIcon from '@coreui/icons-react';
import logoHorizontal from '../../assets/images/logo-findpet-dark.svg';
import isoLogo from '../../assets/images/icon-findpet-dark.svg';
import { AppContext } from '../../context/AppContext';
import { SidebarNav } from '../SidebarNav/SidebarNav';
import navigation from '../../data/_nav';
import './Sidebar.css';
import { cilExitToApp } from '@coreui/icons';
import { AuthContext } from '../../context/AuthContext';
import { redirect, useNavigate } from 'react-router-dom';




const Sidebar = () => {

  //Context
  const { dispatcher, state } = React.useContext(AppContext);
  const { signOut } = React.useContext(AuthContext);

  const navigate = useNavigate();

  return (
    <CSidebar
      position="fixed"
      visible={state.sidebarShow}
      onVisibleChange={(visible) => {
        dispatcher({ type: 'set', sidebarShow: visible });
      }}
    >
      <CSidebarBrand to="/">
        {state.sidebarUnfoldable ? (
          state.sidebarShow ? (
            <img src={isoLogo} />
          ) : (
            <img src={logoHorizontal} />
          )
        ) : (
          <img src={logoHorizontal} />
        )}
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <SidebarNav items={navigation} />
          <CNavItem>
            <CNavLink onClick={() => {signOut()}}>
              <CIcon icon={cilExitToApp} className="nav-icon" />
              Salir
            </CNavLink>
          </CNavItem>
        </SimpleBar>
      </CSidebarNav>

      <CSidebarToggler
        className="d-flex d-lg-none"
        onClick={() => dispatcher({ type: 'set', sidebarUnfoldable: !state.sidebarUnfoldable })}
      />
    </CSidebar>
  );
};
export default Sidebar;
