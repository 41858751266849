import {
  CButton,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react';
import React from 'react';
import styles from './Table.module.css';
import axios from 'axios'
import { ErrorHandler } from '../../utils/ErrorHandler';
import { API_URL } from '../../constants';



const Table = (props) => {

  const handleAction = (id, ruf, name) => {
    function downloadURI(uri, name) {
      var link = document.createElement("a");
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    axios
      .get(`${API_URL}admin/pets/${id}/qr`, {responseType:"blob"})
      .then((res) => {
        let reader = new window.FileReader();
        reader.readAsDataURL(res.data); 
        reader.onload = function() {
        let imageDataUrl = reader.result;
        downloadURI(imageDataUrl, `QR-${name}-${ruf}.png`);
      }})
      .catch((err) => ErrorHandler(err));
  }

  return (
    <>
      {/* <CCol xs={12} className="mt-4 mb-5">
        <CFormInput
          type="search"
          placeholder="Buscar por nombre de usuario"
          // onChange={handleFilter}
          style={{ width: '100%' }}
        />
      </CCol> */}
        <CTable responsive hover>
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell scope="col">RUF</CTableHeaderCell>
              <CTableHeaderCell scope="col">Nombre</CTableHeaderCell>
              <CTableHeaderCell scope="col">Edad</CTableHeaderCell>
              <CTableHeaderCell scope="col">Sexo</CTableHeaderCell>
              <CTableDataCell scope="col">Acciones</CTableDataCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {props.data?.map((el, index) => (
              <CTableRow key={index}>
                <CTableDataCell>{el.pet_identification}</CTableDataCell>
                <CTableDataCell>{el.name}</CTableDataCell>
                <CTableDataCell>{el.age}</CTableDataCell>
                <CTableDataCell>{el.gender.name}</CTableDataCell>
                <CTableDataCell>
                  <CButton 
                    className={`${styles['downloadQR']}`} 
                    style={{width:"max-content"}}
                    onClick={() => handleAction(el.id, el.pet_identification, el.name)}
                  >
                    Descargar QR
                  </CButton>
                </CTableDataCell>
              </CTableRow>
            ))}
          </CTableBody>
        </CTable>
    </>
  );
};

export default Table;
