import {
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react';
import React, {useContext, useState} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styles from './Table.module.css';
import { AppContext } from '../../context/AppContext';
import TableFooter from './TableFooter';




const Table = ({ columns, data, pagination }) => {

  const {setBranchesPageUrl} = useContext(AppContext);

  //Navigation
  const navigate = useNavigate();

  const [modal, setModal] = useState({show:false, content:{}})


  return (
    <>
      {/* <CCol className="mt-4 mb-5">
        <CFormInput
          type="search"
          placeholder="Buscar por Nº de pedido"
          style={{ width: '100%' }}
        />
      </CCol>
      <CCol className="mt-4 mb-5">
        <CFormInput
          type="search"
          placeholder="Buscar por nombre o apellido"
          style={{ width: '100%' }}
        />
      </CCol> */}

        <CTable responsive hover>
          <CTableHead>
            <CTableRow>
              {columns.map((col, index) => (
                <CTableHeaderCell key={index} scope="col">
                  {col.label}
                </CTableHeaderCell>
              ))}
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {data?.map((branch, index) => (
              <CTableRow key={index}>
                <CTableDataCell>
                  <Link to={`/dashboard/companies/${branch?.company?.id}`} style={{color:"black"}}>
                    {branch?.company?.company_name}
                  </Link>
                </CTableDataCell>
                <CTableDataCell>{branch?.name}</CTableDataCell>
                <CTableDataCell>{branch?.branch_status?.name}</CTableDataCell>
                <CTableDataCell>
                  {branch.company?.company_category?.name}
                </CTableDataCell>
                <CTableDataCell>{branch.email}</CTableDataCell>
                <CTableDataCell>{branch.phone || "-"}</CTableDataCell>
                <CTableDataCell>{branch.whatsapp || "-"}</CTableDataCell>
                <CTableDataCell>{branch.location.address}</CTableDataCell>
                <CTableDataCell>
                  <CButton 
                    className={`${styles['downloadQR']}`} 
                    style={{width:"max-content"}}
                    onClick={() => setModal({show:true, content:branch?.benefits,})}
                  >
                    Ver promociones
                  </CButton>
                </CTableDataCell>
              </CTableRow>
            ))}
          </CTableBody>
        </CTable>
        <TableFooter range={pagination?.links} setPageUrl={setBranchesPageUrl} />
        <CModal visible={modal.show} onClose={() => setModal({show:false, content:{}})}>
          <CModalHeader>
              Promociones
          </CModalHeader>
          <CModalBody>
            {modal?.content.length ?
              <ul>
                {modal?.content?.map((item,i) => (
                  <li className='mb-2'>
                    <b>Promoción:</b> {item.description}<br/><b>Código:</b> {item.coupon || "-"}
                  </li>
                ))}
              </ul> :
              <p className='mb-4'>
                Esta sucursal todavía no tiene ninguna promoción disponible.
              </p>
            }
            <CButton
              onClick={() => setModal({show:false, content:{}})}
            >
              Cerrar
            </CButton>
          </CModalBody>
        </CModal>
    </>
  );
};

export default Table;
