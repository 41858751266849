import {
  CButton,
  CCol,
  CFormInput,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react';
import React, {useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Table.module.css';
import { AppContext } from '../../context/AppContext';
import TableFooter from './TableFooter';
import { PUBLIC_URL } from '../../constants';




const Table = ({ columns, data, pagination }) => {

  const {setLocationsPageUrl} = useContext(AppContext);

  //Navigation
  const navigate = useNavigate();

  return (
    <>
      {/* <CCol className="mt-4 mb-5">
        <CFormInput
          type="search"
          placeholder="Buscar por Nº de pedido"
          style={{ width: '100%' }}
        />
      </CCol>
      <CCol className="mt-4 mb-5">
        <CFormInput
          type="search"
          placeholder="Buscar por nombre o apellido"
          style={{ width: '100%' }}
        />
      </CCol> */}

        <CTable responsive hover>
          <CTableHead>
            <CTableRow>
              {columns.map((col, index) => (
                col.key == "actions" ?
                <CTableHeaderCell key={index} scope="col" style={{textAlign:'end'}}>
                  {col.label}
                </CTableHeaderCell> :
                <CTableHeaderCell key={index} scope="col">
                {col.label}
              </CTableHeaderCell> 
              ))}
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {data?.map((location, index) => (
              <CTableRow key={index}>
                <CTableDataCell>{location.id}</CTableDataCell>
                <CTableDataCell>
                  {location.name}
                </CTableDataCell>
                <CTableDataCell>
                  {location.children.map(city => city.name).join(' - ')}
                </CTableDataCell>
                <CTableDataCell style={{textAlign:'end'}}>
                  <CButton 
                    className={`${styles['downloadQR']}`} 
                    style={{width:"max-content"}}
                    onClick={() => navigate(`/dashboard/locations/${location?.id}`)}
                  >
                    Editar
                  </CButton>
                </CTableDataCell>
              </CTableRow>
            ))}
          </CTableBody>
        </CTable>
        <TableFooter range={pagination?.links} setPageUrl={setLocationsPageUrl} />
    </>
  );
};

export default Table;
