import { CContainer, CRow, CCol } from '@coreui/react';
import React, {useEffect} from 'react';
import { CardMetrics, CustomBreadcrumbs,  } from '../../components';
import SheltersTable from '../../components/Table/SheltersTable';
import { AppContext } from '../../context/AppContext';
import heart from '../../assets/images/cil-heart.svg';
import home from '../../assets/images/cil-house.svg';
import paw from '../../assets/images/cil-paw.svg';

const Shelters = () => {

  const { state, getShelters, sheltersPageUrl, getSheltersStats} = React.useContext(AppContext);
  const columns = [
    {
      key: '',
      label: '#',
      _props: { scope: 'col' }
    },
    {
      key: 'name',
      label: 'Nombre',
      _props: { scope: 'col' }
    },
    {
      key: 'owner',
      label: 'Responsable',
      _props: { scope: 'col' }
    },
    {
      key: 'email',
      label: 'Email',
      _props: { scope: 'col' }
    },
    {
      key: 'whatsapp',
      label: 'Whatsapp',
      _props: { scope: 'col' }
    },
    {
      key: 'location',
      label: 'Ubicación/es',
      _props: { scope: 'col' }
    },
    {
      key: 'status',
      label: 'Estado',
      _props: { scope: 'col' }
    },
    {
      key: 'actions',
      label: 'Acciones',
      _props: { scope: 'col' }
    }
  ];

  //Effects
  useEffect(() => {
    getShelters();
    getSheltersStats();
  }, [sheltersPageUrl]);


  return (
    <div>
      <CustomBreadcrumbs parentTitle={'Refugios'} activeTitle="Refugios" />
      <CContainer>
        <CRow className="justify-content-between">
            <CCol xs={12} lg={4}>
              <CardMetrics
                title={state?.sheltersStats?.animal_shelters?.total} 
                subtitle="Refugios totales" 
                cardStyle="primary" 
                icon={home}
              />
            </CCol>
            <CCol xs={12} lg={4}>
                <CardMetrics 
                  title={state?.sheltersStats?.pets?.in_adoption} 
                  subtitle="Animalitos en adopción" 
                  cardStyle="secondary" 
                  icon={paw}
                />
            </CCol>
            <CCol xs={12} lg={4}>
              <CardMetrics 
                title={state?.sheltersStats?.pets?.adopted} 
                subtitle="Animalitos adoptados" 
                cardStyle="tertiary" 
                icon={heart}
              />
            </CCol>
        </CRow>
        <CRow>
          <CCol xs={12}>
            <h2 className='title mb-4'>Refugios</h2>
          </CCol>
          <SheltersTable
            columns={columns} 
            data={state?.shelters} 
            pagination={state?.sheltersPagination} 
          />
        </CRow>
      </CContainer>
    </div>
  );
};

export default Shelters;
