import {
  CButton,
  CCol,
  CFormInput,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react';
import React, { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import styles from './Table.module.css';
import TableFooter from './TableFooter';



const Table = ({ columns, data, pagination }) => {

  const {setUsersPageUrl} = useContext(AppContext);

  return (
    <>
      {/* <CCol xs={12} className="mt-4 mb-5">
        <CFormInput
          type="search"
          placeholder="Buscar por nombre de usuario"
          // onChange={handleFilter}
          style={{ width: '100%' }}
        />
      </CCol> */}

        <CTable responsive hover>
          <CTableHead>
            <CTableRow>
              {columns.map((col, index) => (
                <CTableHeaderCell key={index} scope="col">
                  {col.label}
                </CTableHeaderCell>
              ))}
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {data.map((el, index) => (
              <CTableRow key={index}>
                <CTableDataCell>{el.id}</CTableDataCell>
                <CTableDataCell>{el.name}</CTableDataCell>
                <CTableDataCell>{el.email}</CTableDataCell>
                <CTableDataCell>{el.alternative_email || "-"}</CTableDataCell>
                <CTableDataCell>{el.phone}</CTableDataCell>
                {/* <CTableDataCell>
                  <CButton
                    className={el.is_active ? styles['isActive'] : styles['isSuspended']}
                    // onClick={() => handleAction(el.id)}
                  >
                    {el.is_active ? 'Suspender' : 'Reactivar'}
                  </CButton>
                </CTableDataCell> */}
              </CTableRow>
            ))}
          </CTableBody>
        </CTable>
        <TableFooter range={pagination?.links} setPageUrl={setUsersPageUrl} />
    </>
  );
};

export default Table;
