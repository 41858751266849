import axios from 'axios';
import React, { useState } from 'react';
import { API_URL } from '../constants';
import { ErrorHandler } from '../utils/ErrorHandler';
import download from 'downloadjs'
import { getDate } from '../utils/getDate';
const AlertsContext = React.createContext();
const { Provider } = AlertsContext;



const AlertsProvider = ({ children }) => {

  //States
  const changeState = (state = initialState, { type, ...rest }) => {
    switch (type) {
      case 'set':
        return { ...state, ...rest };
      default:
        return state;
    }
  };
  
  const initialState = {
    sidebarShow: true,
    sidebarUnfoldable: true,
    alerts: [],
    petGenders:[],
    petSpecies:[],
    alertTypes:[],
    alertStatuses:[]
  };
  const [alertsState, dispatcher] = React.useReducer(changeState, initialState);
  const [alertsPageUrl, setAlertsPageUrl] = useState(`${API_URL}admin/alerts`);
  const [loading, setLoading] = useState(false);


  //Alerts
  const getAlerts = (params) => {
    axios
      .get(`${alertsPageUrl}`,{params: params})
      .then((res) => {
        dispatcher({ type: 'set', alerts: res.data.data });
        dispatcher({ type: 'set', alertsPagination: res.data.meta });
      })
      .catch((err) => ErrorHandler(err));
  };
  const getSummaryAlerts = () => {
    axios
    .get(`${API_URL}admin/alerts/summary`)
    .then((res) => {
      dispatcher({ type: 'set', alertsSummary: res.data });
    })
    .catch((err) => ErrorHandler(err));
  }
  const getAlertsFile = () => {
    function downloadURI(uri, name) {
      var link = document.createElement("a");
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    axios
      .get(`${API_URL}admin/alerts/file`, {responseType:"blob"})
      .then((res) => {
        let reader = new window.FileReader();
        reader.readAsDataURL(res.data); 
        reader.onload = function() {
        let fileDataUrl = reader.result;
        downloadURI(fileDataUrl, `Findpet - Listado completo de alertas - ${getDate(new Date())}`);
      }})
      .catch((err) => ErrorHandler(err));
  }


  // Filters data
  const getPetGenders = () => {
    axios
      .get(`${API_URL}genders`)
      .then((res) => {
        dispatcher({ type: 'set', petGenders: res.data });
      })
      .catch((err) => ErrorHandler(err));
  };
  const getPetSpecies = () => {
    axios
      .get(`${API_URL}species`)
      .then((res) => {
        dispatcher({ type: 'set', petSpecies: res.data });
      })
      .catch((err) => ErrorHandler(err));
  };
  const getAlertTypes = () => {
    axios
      .get(`${API_URL}alerts/types`)
      .then((res) => {
        dispatcher({ type: 'set', alertTypes: res.data });
      })
      .catch((err) => ErrorHandler(err));
  };
  const getAlertStatuses = () => {
    axios
      .get(`${API_URL}alerts/status`)
      .then((res) => {
        dispatcher({ type: 'set', alertStatuses: res.data });
      })
      .catch((err) => ErrorHandler(err));
  };


  return (
    <Provider
      value={{
        getAlerts,
        getPetGenders, getPetSpecies, 
        getAlertTypes, getAlertStatuses, 
        alertsPageUrl, setAlertsPageUrl,
        loading, 
        dispatcher,
        alertsState,
        getSummaryAlerts, getAlertsFile
      }}>
      {children}
    </Provider>
  );
};
export { AlertsContext, AlertsProvider };
